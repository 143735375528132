
.App {
  text-align: center;
  background-color: rgb(253, 251, 253);
}
@font-face {
  font-family: Cinzel;
  src: url("fonts/Cinzel/Cinzel-VariableFont_wght.ttf");
}
@font-face {
  font-family: Cinzel-SemiBold;
  src: url("fonts/Cinzel/static/Cinzel-SemiBold.ttf");
}
@font-face {
  font-family: Cinzel-Bold;
  src: url("fonts/Cinzel/static/Cinzel-Bold.ttf");
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url("tarot-divide-header.jpg");
  background-position: center;
  height: 225px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1em + 2vmin);
  color: white;
  font-family: Cinzel;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  padding-top: 4px;
  font-size: 2.5em;
  font-family: Cinzel;
}

.dropdownDiv{
  display: flex;
  justify-content: end;
  padding: 10px;
}

.card-wrapper{
  border-style: double;
  margin: 8px;
  font-family: SemiBold-Bold;
  cursor: pointer;
}

.grid-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

img {
  width: 320px;
  height: 450px;
  margin: 4px;
}
.title {
  font-size: x-large;
  padding-bottom: 5px;
}