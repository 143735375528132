@font-face {
    font-family: Cinzel;
    src: url("fonts/Cinzel/Cinzel-VariableFont_wght.ttf");
  } 

  @font-face {
    font-family: Playfair_Display;
    src: url("fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf");
  } 
 
@media screen and (min-width: 801px) {
 .modal {
    background: rgb(230, 224, 224);
    color: rgb(8, 8, 8);
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    height: 96%;
    font-family: Cinzel;
    padding-top: 20px;
  }

  .modal-div {
    display: flex;
    flex-direction: row;
    height: 96%;
  }

  .cardImage {
    height: 100%;
    width: auto;
    margin: 4px;
    padding: 5px;
    padding-left: 20px;
    margin-left: 15px;
    
  }

  .closeButton {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
  }

  .card-details-container {
    width: 35%;
    font-family: Playfair_Display;
    padding-left: 50px;
    padding-right: 30px;
    padding-top: 30px;
  }

  .modal-title {
    font-size: 2.5em;
    padding: 5px;
    padding-bottom: 20px;
  }

  .modal-created-date{
      font-size: 18px;
      font-family: Playfair_Display;
  }

  .modal-dimension{
    font-size: 1em;
    padding-bottom: 20px;
    font-family: Playfair_Display;
  }

  .modal-text{
      max-width: 500px;
      font-size: 1em;
      font-family: Playfair_Display;
  }
}

@media screen and (max-width: 800px) {
  .modal {
    background: rgb(230, 224, 224);
    color: rgb(8, 8, 8);
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    height: 99%;
    font-family: Cinzel;
  }
  .closeButton {
    position: absolute;
    top: 0;
    right: 0;
    height: 4%;

  }
  .modal-div {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  .cardImage {
    height: fit-content;
    width: auto;
    padding: 2%;
    padding-top: 2%;
  }
  .card-details-container {
    width: auto;
    height:fit-content;
    font-family: Playfair_Display;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 1%;
    padding-bottom: 2%;
  }
  .modal-title {
    font-size: 150%;
    padding-bottom: 2%;
  }
  .modal-text {
    font-size: 100%;
    padding-top: 2%;
    padding-bottom: 1%;
  }
}
